@import '@/styles/mixins.scss';

.container {
    @include flex-column;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
    max-width: 1220px;
    padding: 80px;
    margin: auto;
}

.content-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 52px;
}

.content {
    @include flex-column;
    gap: 16px;
}

.title {
    @include headline-2;
    color: var(--colors-primary-dark-90, #020b18);
    margin: 0;
}

.subtitle {
    @include headline-6;
    color: var(--colors-primary);
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-40);
    margin: 0;

    p {
        margin-bottom: 4px;
    }

    ul {
        margin: 0;
        padding-left: 24px;
    }

    li {
        padding-bottom: 4px;
    }
}

.icon-wrap {
    @include flex-column;
    gap: 52px;
}

@media (max-width: 1280px) {
    .container {
        padding: 60px;
    }
}

@media (max-width: 960px) {
    .content-wrap {
        grid-template-columns: 1fr;
        gap: 40px;
    }
}

@media (max-width: 764px) {
    .section-wrap {
        padding: 0;
    }
    .container {
        gap: 16px;
        padding: 44px 40px;
        border-radius: 0;
    }

    .icon-wrap {
        gap: 40px;
    }
}

@media (max-width: 524px) {
    .container {
        padding: 44px 24px;
    }
}
