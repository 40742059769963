@import '@/styles/mixins.scss';

.wrap {
    @include flex-row;
    gap: 20px;
    justify-content: center;
}

.icon-wrap {
    @include flex-center;
    padding: 0 12px;
    width: 36px;
    height: 36px;
    border-radius: 29px;
    background: var(--colors-primary-light-90, #e8f1fd);
}

.icon {
    width: 20px;
    height: 20px;
    color: var(--colors-primary);

    flex-shrink: 0;

    transition: ease-in 150ms;
}

.content-wrap {
    @include flex-column;
    gap: 12px;
}

.title {
    @include headline-5;
    color: var(--colors-primary);
    margin: 0;
}

.description {
    @include text-L;
    color: var(--colors-gray-dark-30);
    margin: 0;
}

@media (max-width: 960px) {
    .wrap {
        flex-direction: column;
    }
}

@media (max-width: 764px) {
    .wrap {
        gap: 24px;
    }
}
