@import '@/styles/mixins.scss';

.section-wrap {
    opacity: 0.01;
    transform: translateY(60px);

    &.enter {
        opacity: 1;
        transform: translateY(0px);
        transition: 150ms ease-out;
    }
}

.section-title {
    margin: 0 auto 60px auto !important;
    padding: 20px;
}

.gradient-end {
    position: absolute;
    top: 350px;
}

.wrapper {
    @include flex-column;
    padding: 80px;
    gap: 24px;

    border-radius: 16px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
}

.title,
.reset-button {
    @include headline-6;
    color: var(--colors-primary);
}

.reset-button {
    @include flex-center;
    cursor: pointer;
    width: fit-content;

    gap: 8px;

    svg {
        width: 16px;
        height: 16px;
        stroke: var(--colors-primary);
    }
}

.navigation-buttons {
    @include flex-row;
    gap: 16px;

    margin-top: 40px;

    &.results {
        margin-top: 16px;
        flex-grow: 1;

        align-items: flex-end;
    }
}

.button {
    @include flex-center;
    @include caption-L;

    height: 44px;
    padding: 10px 32px;
    border-radius: 32px;

    cursor: pointer;
    outline: none;

    transition: 150ms ease-in-out;

    &:disabled {
        color: var(--colors-gray-light-50) !important;
        border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
        background: var(--colors-gray-light-95, #fafafb);
        box-shadow: none !important;

        cursor: default;

        &:hover {
            color: var(--colors-gray-light-50) !important;
            border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
            background: var(--colors-gray-light-95, #fafafb);
            box-shadow: none !important;
        }
    }
}

.back-button {
    color: var(--colors-gray-dark-20, #7a828f) !important;
    border: 1px solid var(--colors-mono-black-opacity-10, rgba(0, 0, 0, 0.1));
    background: var(--colors-mono-white-opacity-100, #fff);

    &:hover {
        color: var(--colors-primary);
        border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    }

    &:active {
        background: var(--colors-gray-light-90);
    }
}

.continue-button {
    color: var(--white);
    background: var(--colors-primary);
    border: 1px solid var(--colors-primary);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);

    &:hover {
        background: var(--colors-primary-dark-10);
    }

    &:active {
        background: var(--colors-primary-dark-20);
    }
}

.email-button {
    color: var(--colors-primary);
    background: var(--white);
    border: 1px solid transparent;

    &:hover {
        color: var(--colors-primary-dark-10);
    }

    &:active {
        color: var(--colors-primary-dark-20);
    }
}

.multi-q-wrap,
.results-wrapper {
    @include flex-column;
    gap: 60px;
}

.question-wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 26px));
    gap: 52px;

    min-height: 423px;

    &.sub-question {
        min-height: 0;
    }
}

.left {
    @include flex-column;
    justify-content: space-between;
}

.results-content {
    @include flex-row;
    gap: 72px;
}

.content,
.results-text-wrap {
    @include flex-column;
    gap: 16px;

    .line {
        margin: 8px 0;
    }
}

.results-text-wrap {
    flex-grow: 1;
}

.question-title {
    @include headline-2;
    color: var(--colors-primary-dark-90, #020b18);
    margin: 0;
}

.description,
.ties-section {
    @include text-M;
    color: var(--colors-gray-dark-40, #5b616b);
    margin: 0;

    &.disclaimer {
        font-size: 14px;
        font-style: italic;
        font-weight: 300;
        line-height: 150%; /* 21px */
    }

    p {
        margin: 0;
    }
}

.ties-content {
    margin-bottom: 40px;
}

.ties-header {
    @include headline-5;
    display: block;
    color: var(--colors-mono-black-80);
    margin: 0 0 16px 0;
}

.right {
    @include flex-column;
    gap: 52px;
}

.single-select-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
}

.boolean-wrapper {
    @include flex-column;
    gap: 24px;
}

.option-input {
    display: none;

    &:checked + .option-label {
        border-color: var(--colors-primary);

        &:hover {
            border-width: 2px;
        }

        .option-symbol {
            background: var(--colors-primary-light-90, #e8f1fd);
            border: 1px solid var(--colors-primary);

            .dot {
                width: 8px;
                height: 8px;
                border-radius: 4px;

                background: var(--colors-primary);
            }
        }
    }
}

.option-label {
    @include flex-center;
    padding: 24px;
    gap: 8px;

    border-radius: 16px;
    background: var(--colors-mono-white-opacity-100, #fff);
    cursor: pointer;

    transition: 100ms ease-in-out border-color;

    border-style: solid;
    border-width: 1px;
    border-color: var(--colors-mono-black-opacity-10, rgba(0, 0, 0, 0.1));

    user-select: none;

    &:hover {
        padding: 23px;
        border-color: var(--colors-primary-light-50);
        border-width: 2px;
    }
}

.option-symbol {
    @include flex-center;

    width: 16px;
    height: 16px;

    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-70, #e0e3e8);
    background: var(--colors-mono-white-opacity-100, #fff);
}

.option-name {
    @include subtitle-M;
    color: var(--colors-gray-dark-50, #4c515a);

    flex-grow: 1;
}

.line {
    height: 1px;
    width: 100%;
    background-color: var(--colors-mono-black-opacity-5);
    border: none;
}

.results-graphic {
    width: 312px;

    background-image: url('/images/non-resident-graphic.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    border-radius: 16px;
    border: 1px solid var(--colors-warning-light-90);

    flex-shrink: 0;

    &.resident {
        background-image: url('/images/resident-graphic.png');
        border: 1px solid var(--colors-success-light-90);
    }

    &.mobile {
        display: none;
    }
}

.social-section {
    @include flex-center;
    flex-direction: column;
    gap: 16px;
    padding: 40px 20px;
}

.social-title {
    @include headline-5;

    color: var(--colors-mono-black-opacity-80);
    text-align: center;
    margin: 0;
}

.social-text {
    @include text-M;
    color: var(--colors-gray-dark-40, #5b616b);
    text-align: center;
    margin: 0;
}

.social-icons {
    display: inline-grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.social-icon {
    @include flex-center;
    width: 56px;
    height: 56px;

    border-radius: 100px;

    cursor: pointer;
    transition: 150ms ease-in-out;

    &:hover {
        background: var(--colors-primary-light-95);
    }
}

.link {
    color: var(--colors-primary);
    font-weight: 500;
    text-decoration: none;
}

.emphasis {
    // color: var(--colors-gray-dark-60, #5b616b);
    font-weight: 600;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 60px;
    }

    .single-select-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1079px) {
    .results-graphic {
        height: auto;
        width: 260px;

        object-fit: cover;
    }
}

@media screen and (max-width: 1024px) {
    .single-select-grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .question-wrapper {
        @include flex-column;
    }

    .navigation-buttons {
        justify-content: space-between;
    }

    .multi-q-wrap {
        gap: 40px;
    }

    .results-graphic {
        display: none;

        &.mobile {
            display: block;

            height: 225px;
            width: 100%;

            background-size: 312px 312px;
            background-color: var(--colors-warning-light-95);

            &.resident {
                background-color: var(--colors-success-light-95);
            }
        }
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 40px 0;
    }

    .wrapper {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0 40px;
    }

    .single-select-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 524px) {
    .single-select-grid {
        grid-template-columns: minmax(0, 1fr);
    }

    .wrapper {
        padding: 0 24px;
    }

    .navigation-buttons {
        flex-direction: column-reverse;
        gap: 24px;

        &.results {
            flex-direction: column;
        }

        button {
            width: 100%;
        }
    }
}
