@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;
    gap: 52px;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    div {
        border: 1px solid transparent;
    }

    --border-config: 1px solid var(--colors-mono-black-opacity-5);

    div:nth-child(1),
    div:nth-child(2) {
        border-right: var(--border-config);
        border-bottom: var(--border-config);
    }

    div:nth-child(3) {
        border-bottom: var(--border-config);
    }

    div:nth-child(4),
    div:nth-child(5) {
        border-right: var(--border-config);
    }
}

@media screen and (max-width: 1280px) {
    .stats-grid {
        grid-template-columns: repeat(2, 1fr);

        div:nth-child(2),
        div:nth-child(4) {
            border-right: 0;
            border-bottom: var(--border-config);
        }

        div:nth-child(3) {
            border-right: var(--border-config);
        }
    }
}

@media screen and (max-width: 524px) {
    .stats-grid {
        grid-template-columns: 1fr;

        div {
            border-right: 0 !important;
            border-bottom: var(--border-config);
        }

        div:nth-child(6) {
            border-bottom: 0;
        }
    }
}
