@import '@/styles/mixins.scss';

.wrapper {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    align-items: center;
    color: #040f1e;
    gap: 60px;

    &.right {
        > *:first-child {
            order: 1;
        }
    }
}

.image-background {
    &.phone-image {
        display: none;
    }
    &.desktop-image {
        display: block;
    }
}

.content {
    @include flex-column;
}

.title {
    @include headline-2;
    color: var(--colors-primary);
    margin: 0 0 20px 0;

    &.primary {
        color: var(--colors-primary);
    }
}

.content-wrap {
    @include flex-column;
    gap: 32px;
}

.desc-wrap {
    @include flex-column;
    gap: 40px;
    flex-grow: 1;
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-50);
    margin: 0;
}

.buttons {
    @include flex-row;
    gap: 10px;
}

.pagination {
    @include flex-row;
    gap: 10px;
    align-items: center;
}

.page {
    @include subtitle-XS;
    color: var(--colors-gray);
    padding: 6px 12px;

    &.active {
        border-radius: 5px;
        background: var(--colors-gray-light-90, #f5f6f7);
    }

    &:hover {
        cursor: pointer;
    }
}

.svg-wrapper {
    svg {
        width: 24px;
        height: 24px;
        stroke: var(--colors-gray-light-50);

        &:hover {
            cursor: pointer;
        }
    }
}

.image-background {
    @include flex-center;
    border-radius: 16px;
    max-width: 100%;
    overflow: hidden;
}

.image-wrap {
    position: relative;
    min-height: 300px;
    height: 100%;
    width: 100%;
}

.image {
    max-height: 500px;
    max-width: 100%;
    object-fit: scale-down;
}

/* starting ENTER animation */
.content-enter {
    transform: translateX(-30px);
    opacity: 0.01;
}

/* ending ENTER animation */
.content-enter-active {
    transition: 200ms ease-out;
    transform: translateX(0);
    opacity: 1;
}

/* starting EXIT animation */
.content-exit {
    opacity: 1;
}

/* ending EXIT animation */
.content-exit-active {
    transition: 200ms ease-out;
    opacity: 0.01;
}

/* starting ENTER animation */
.image-enter {
    opacity: 0.01;
}

/* ending ENTER animation */
.image-enter-active {
    transition-delay: 200ms;
    transition: 200ms ease-out;
    opacity: 1;
}

/* starting EXIT animation */
.image-exit {
    opacity: 1;
}

/* ending EXIT animation */
.image-exit-active {
    transition-delay: 200ms;
    transition: 200ms ease-out;
    opacity: 0.01;
}

@media screen and (max-width: 960px) {
    .wrapper {
        grid-template-columns: 1fr;
    }

    .image-background {
        &.phone-image {
            display: block;
        }
        &.desktop-image {
            display: none;
        }
    }
}

@media screen and (max-width: 524px) {
    .buttons {
        @include flex-column;
        gap: 20px;
    }

    .pagination {
        justify-content: center;
    }
}
