@import '@/styles/mixins.scss';

.container {
    @include flex-column;

    &.blue {
        padding: 70px 70px 80px;
    }
}

.wrapper {
    padding: 80px;
    border-radius: 16px;
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
}

.tabs-wrapper {
    position: relative;
}

.tab-row-wrap {
    position: relative;
    width: 100%;
    padding: 0 32px;

    overflow-x: auto;

    // Scroller Styles

    /* width */
    &::-webkit-scrollbar {
        height: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--colors-primary-light-90);
        border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--colors-primary-light-70);
        border-radius: 5px;
    }
}

.tabs {
    @include flex-row;
    align-items: flex-start;
    padding: 0px 40px 0 0;
    gap: 60px;
    width: max-content;

    &.auto-scroll {
        gap: 24px;
    }
}

.tab {
    @include flex-column;
    gap: 4px;
    padding: 0px 0px 16px;
    opacity: 0.6;
    border-bottom: 4px solid transparent;
    transition: 150ms ease-out;
    user-select: none;
    flex-shrink: 0;

    &.active {
        opacity: 1;
        border-bottom: 4px solid var(--colors-primary);

        .name {
            color: var(--colors-gray-dark-10);
        }
    }

    &:hover {
        opacity: 1;
        border-bottom: 4px solid var(--colors-primary);
        cursor: pointer;
    }

    opacity: 0.6;
    transform: translateX(0px);

    &.hidden {
        opacity: 0.01;
        transform: translateX(40px);
    }
}

.content-wrapper {
    @include flex-row;
    gap: 24px;
    align-items: center;
}

.content {
    opacity: 0.01;
    flex-grow: 1;

    &.enter {
        opacity: 1;
        transition-delay: 300ms;
    }
}

.name {
    @include subtitle-L;
    color: var(--colors-gray-dark-10);
}

.subtitle {
    @include subtitle-S;
    color: var(--colors-gray);
}

.chevron-wrap {
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    background: linear-gradient(270deg, #fff 12.42%, rgba(255, 255, 255, 0) 100%);

    &.blue {
        background: linear-gradient(270deg, var(--colors-primary-light-95) 12.42%, rgba(255, 255, 255, 0) 100%);
    }
}

.chevron {
    @include flex-center;
    justify-content: flex-end;
    padding-right: 16px;
    width: 111px;
    height: 100%;

    svg {
        width: 24px;
        height: 24px;
        stroke: var(--colors-gray-dark-50);

        &:hover {
            cursor: pointer;
        }
    }
}

.separator {
    margin-top: 9px;
    opacity: 0.01;
    transform: translateX(20px);

    svg {
        width: 24px;
        height: 24px;
        fill: var(--colors-mono-black-opacity-5);
    }

    &.enter {
        opacity: 1;
        transform: translateX(0px);
        transition: 150ms ease-out;
    }
}

@media screen and (max-width: 1280px) {
    .container {
        &.blue {
            padding: 70px 60px 80px;
        }
    }
    .wrapper {
        padding: 60px;
    }
}

@media screen and (max-width: 764px) {
    .container {
        &.blue {
            padding: 50px 40px 60px;
        }
    }
    .wrapper {
        padding: 52px 40px;
    }
}

@media screen and (max-width: 524px) {
    .container {
        &.blue {
            padding: 50px 16px 60px;
        }
    }

    .wrapper {
        padding: 52px 32px;
    }
}

@media (hover: none) {
    .tab:not(.active):hover {
        opacity: 0.6;
        border-bottom: 4px solid transparent;
    }
}
