@import '@/styles/mixins.scss';

.residence-table {
    @include text-S;
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-template-rows: repeat(2, 44px) repeat(6, 64px);

    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-60, #d6dae1);
    background: var(--colors-mono-white-opacity-100, #fff);

    /* Shadow/md */
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);

    color: var(--colors-gray-dark-10);
}

.corner {
    @include flex-center;
    grid-row: 1 / 3;

    padding: 12px 24px;

    border: 1px solid var(--colors-mono-black-opacity-5);
    border-width: 0 1px 1px 0;
}

.title {
    grid-column: 2 / 6;

    padding: 12px 24px;

    border: 1px solid var(--colors-mono-black-opacity-5);
    border-width: 0 0 1px 0;

    &.residentTitle {
        grid-column: 2 / 7;
    }
}

.mobile-ties-row {
    display: none;
    grid-column: 2 / 6;
    grid-row: 2;

    padding: 12px 24px;

    border: 1px solid var(--colors-mono-black-opacity-5);
    border-width: 0 0 1px 0;

    &.residentTitle {
        grid-column: 2 / 7;
    }
}

.mobile-legend {
    display: none;
    grid-column: 1 / 6;

    padding: 16px 12px;

    border: 1px solid var(--colors-mono-black-opacity-5);
    border-width: 0 0 1px 0;

    gap: 20px;

    &.residentTitle {
        grid-column: 1 / 7;
    }
}

.legend-section {
    @include flex-center;
    @include caption-S;
    gap: 6px;
}

.days-label {
    @include flex-column;
    justify-content: center;
    grid-column: 1;

    padding: 12px 24px;

    border: 1px solid var(--colors-mono-black-opacity-5);
    border-width: 0 1px 1px 0;
}

.ties-title {
    border: 1px solid var(--colors-mono-black-opacity-5);
    border-width: 0 1px 1px 0;

    grid-row: 2;

    &.last {
        border-width: 0 0 1px 0;
    }
}

.resident-box,
.ties-title {
    @include flex-center;
    padding: 12px;
}

.ties-text-number {
    display: none;
    text-align: center;
}

.days-text-short {
    display: none;
}

.resident-box {
    border: 1px solid var(--colors-mono-black-opacity-5);
    border-width: 0 1px 1px 0;

    @for $i from 0 through 6 {
        &.box-#{$i} {
            grid-row: #{$i + 3};
        }
    }

    &.position {
        background: var(--colors-warning-light-90);
        border: 1px solid var(--colors-warning-light-50);

        .resident-status {
            color: var(--white);
            background: var(--colors-warning);

            &.resident {
                background: var(--colors-success);
            }
        }

        &.resident {
            background: var(--colors-success-light-90);
            border: 1px solid var(--colors-success-light-50);
        }
    }
}

.resident-status {
    @include caption-S;
    font-weight: 400;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 16px;
    background: var(--colors-warning-light-90);

    color: var(--colors-warning-dark-20, #c67307);

    &.resident {
        color: var(--colors-success-dark-20);
        background: var(--colors-success-light-90);
    }
}

.vertical-line {
    height: 100%;
    width: 1px;
    background-color: var(--colors-mono-black-opacity-5);
}

@media screen and (max-width: 1280px) {
    .residence-table {
        grid-template-columns: 210px repeat(auto-fit, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1024px) {
    .residence-table {
        grid-template-columns: 150px repeat(auto-fit, minmax(0, 1fr));
    }

    .title,
    .mobile-ties-row {
        text-align: center;
    }

    .ties-text {
        display: none;
    }

    .ties-text-number,
    .mobile-ties-row {
        display: block;
    }

    .mobile-legend {
        @include flex-row;
        justify-content: flex-end;
    }

    .corner {
        grid-row: 1 / 4;
    }

    .ties-title {
        grid-row: 3;
    }

    .resident-box {
        @for $i from 0 through 6 {
            &.box-#{$i} {
                grid-row: #{$i + 4};
            }
        }
    }
}

@media screen and (max-width: 524px) {
    .residence-table {
        grid-template-columns: 80px repeat(auto-fit, minmax(0, 1fr));
        grid-template-rows: 66px repeat(2, 33px) repeat(7, 66px);
    }

    .mobile-ties-row {
        padding: 4px 24px;
    }
}

@media screen and (max-width: 580px) {
    .days-text-short {
        display: block;
    }

    .days-text {
        display: none;
    }

    .title {
        @include flex-center;
        padding: 10px 24px;
    }

    .corner {
        padding: 16px 10px;
    }

    .days-label {
        padding: 16px 12px;
    }

    .resident-box {
        padding: 16px 4px;
    }
}
