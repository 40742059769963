@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;
    gap: 12px;
    padding: 40px;
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: opacity 150ms ease-out;
    }
}

.stat {
    @include hero-headline;
    @include flex-center;
    justify-content: flex-start;
    margin: 0;
    color: var(--colors-primary);

    &.reverse {
        flex-direction: row-reverse;
    }
}

.text {
    @include headline-4;
    margin: 0;
    color: var(--colors-gray-dark-90);
}

.icon {
    height: 48px;
    width: 48px;

    stroke: var(--colors-primary-light-80);
    stroke-width: 3px;

    margin-left: 12px;
}

@media screen and (max-width: 764px) {
    .wrapper {
        padding: 24px;
    }
}
