@import '@/styles/mixins.scss';

.modal {
    width: 600px;
}

.graphic {
    width: 100%;
    height: 312px;

    background-image: url('/images/non-resident-graphic.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 312px 312px;

    background-color: var(--colors-warning-light-95);

    border-radius: 16px;
    border-bottom: 1px solid var(--colors-warning-light-90);

    &.resident {
        background-image: url('/images/resident-graphic.png');
        background-color: var(--colors-success-light-95);
        border: 1px solid var(--colors-success-light-90);
    }
}

.content-wrap {
    padding: 60px;

    h2 {
        @include headline-2;
        color: var(--colors-gray-dark-70);
        margin: 0;
    }
    p {
        @include text-M;
        color: var(--colors-gray-dark-40);
        margin: 16px 0;
    }
}

.email-form {
    @include flex-column;
    margin-top: 32px;
}

#newsletter {
    display: none;

    &:checked + .newsletter-label .newsletter-check {
        border: 1px solid var(--colors-primary-dark-0, #1570ef);
        background: var(--colors-primary-light-90, #e8f1fd);

        svg {
            stroke: var(--colors-primary);
            opacity: 1;
        }
    }
}

.newsletter-label {
    @include flex-row;
    color: var(--colors-gray-dark-50);

    gap: 8px;
    padding: 0 6px;
    margin-top: 12px;

    cursor: pointer;
    user-select: none;
}

.newsletter-check {
    @include flex-center;
    width: 16px;
    height: 16px;

    border-radius: 4px;
    border: 1px solid var(--colors-gray-light-70, #e0e3e8);
    background: var(--colors-mono-white-opacity-100, #fff);

    flex-shrink: 0;
    margin-top: 3px;

    svg {
        width: 11px;
        opacity: 0;
    }
}

.newsletter-text {
    @include text-S;
    color: var(--colors-gray-dark-10);

    flex-grow: 1;
}

.send-wrap {
    @include flex-row;

    gap: 12px;

    input {
        height: 44px;
        padding: 10px 13px;

        border-radius: 8px;
        border: 1px solid var(--colors-gray-light-70, #e0e3e8);
        background: var(--colors-mono-white-opacity-100, #fff);

        flex-grow: 1;
    }
}

.button {
    color: var(--white);
    background: var(--colors-primary);
    border: 1px solid var(--colors-primary);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);

    border-radius: 8px;

    padding: 12px 24px;

    cursor: pointer;

    &:hover {
        background: var(--colors-primary-dark-10);
    }

    &:active {
        background: var(--colors-primary-dark-20);
    }
}

.close {
    position: absolute;
    top: 24px;
    right: 24px;

    stroke: var(--colors-mono-black-opacity-40);

    cursor: pointer;

    transition: 150ms ease-in-out;

    &:hover {
        transform: scale(1.1);
        stroke: var(--colors-mono-black-opacity-60);
    }
}

@media screen and (max-width: 764px) {
    .modal {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        width: calc(100% - 40px);

        margin: 0;
        height: auto;
    }

    .content-wrap {
        padding: 40px;
    }
}

@media screen and (max-width: 600px) {
    .modal {
        border-radius: 16px;
    }
}

@media screen and (max-width: 524px) {
    .modal {
        border-radius: 16px;
    }

    .content-wrap {
        padding: 20px;
    }

    .send-wrap {
        flex-direction: column;

        .button {
            width: 100%;
        }
    }
}
